<template>
  <h1>Delete API key</h1>
  <div class="loading-indicator-wrap" v-if="fileName === null">
    <progress-indicator>
      Loading...
    </progress-indicator>
  </div>
  <div v-else>
    <div class="app-block">
      <div style="white-space: nowrap;">for the App:</div>
      <app-title :title="app.title"
                 :store="app.store"
                 :store-link="app.store_link"
                 :logo="app.logo"
                 :logo-size="24" />
    </div>
    <div class="disclaimer">
      <div>
        We will remove this API Key:
      </div>
      <div class="selected-file-wrap">
        <div class="selected-file">
          {{ fileName }}
        </div>
      </div>
    </div>
    <div class="question">
      Are you sure you want to disconnect this app?
    </div>
    <div class="integration-error-block" v-if="apps.length > 0">
      <div class="label">
        <svg-icon icon="exclamation" />
        Removing this API key will disconnect other apps:
      </div>
      <div class="apps">
        <app-title v-for="application in apps" :title="application.title"
                   :store="application.store"
                   :store-link="application.store_link"
                   :logo="application.logo"
                   :logo-size="24" />
      </div>
    </div>
    <div class="buttons">
      <base-button height="40px"
                   width="100px"
                   border-radius="4px"
                   @click="closeModal"
                   classes="cancel-button"
                   mode="just-text">
        Cancel
      </base-button>

      <base-button height="40px"
                   :disabled="inProcess"
                   width="180px"
                   @click="disconnect"
                   border-radius="4px"
                   classes="disconnect-button"
                   mode="outline">
        Yes, Disconnect
      </base-button>
    </div>
  </div>
</template>
<script>
import AppTitle from "@/components/Billets/AppTitle/index.vue";
import {httpRequest} from "@/api";
import ProgressIndicator from "@/components/UI/ProgressIndicator/index.vue";
import SvgIcon from "@/components/UI/SvgIcon/index.vue";

export default {
  name: 'DisconnectKeyModal',
  components: {
    SvgIcon,
    ProgressIndicator,
    AppTitle
  },
  props: {
    app: Object,
  },
  data() {
    return {
      fileName: null,
      apps: [],
      connectAppId: null,
      inProcess: false,
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    closeModal() {
      this.$emit('close-modal', false);
    },
    async loadData() {
      const result = await httpRequest(
        'GET',
        process.env.VUE_APP_API_URL + this.$ApiUrls.integrations.APP_STORE_CONNECT_GET_KEY
          + '?app_id=' + this.app.id,
      );
      if (result.key) {
        this.fileName = result.key.saved_file_name;
        this.apps = result.apps;
        this.connectAppId = result.key.id;
      }
    },
    async disconnect() {
      this.inProcess = true;
      let formData = new FormData();
      formData.append('connect_app_id', this.connectAppId);
      await httpRequest(
        'POST',
        process.env.VUE_APP_API_URL + this.$ApiUrls.integrations.APP_STORE_CONNECT_DISCONNECT_KEY,
        formData
      );
      this.$emit('close-modal', true);
    },
  },
}
</script>
<style lang="scss" scoped>
.selected-file-wrap {
  display: flex;
  column-gap: 16px;
  color: var(--neutral-750);
  font-weight: 600;

  .selected-file {
    background: var(--neutral-100);
    border-radius: 4px;
    padding: 8px 12px;
  }
}

.integration-error-block {
  .label {
    margin-bottom: 12px;
    font-weight: 600;
  }

  .apps {
    padding-left: 19px;
    display: flex;
    flex-direction: column;
    gap: 8px;

    .logo img {
      border-right: 4px;
    }
  }
}

.loading-indicator-wrap {
  position: relative;
  margin-bottom: 180px;
  margin-top: 100px;
}

.disclaimer {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}

.app-block {
  margin-bottom: 16px;
}

.question {
  margin-bottom: 24px;
}
</style>
