<template>
  <div>
    <h1>Delete Integration</h1>
    <div class="app-block">
      <div style="white-space: nowrap;">for the App:</div>
      <app-title :title="app.title"
                 :store="app.store"
                 :store-link="app.store_link"
                 :logo="app.logo"
                 :logo-size="24" />
    </div>
    <div class="question">
      Are you sure you want to disconnect this app?
    </div>
    <div class="disclaimer">
      <p>
        We will <b>stop retrieving and storing data</b> for this app.
      </p>
      <p>
        You will <b>no longer be able to access this data</b> in our interface, and we will delete only the stored data pulled through this integration.
      </p>
    </div>
    <div class="note">
      <div>
        <b>Note:</b>
      </div>
      <div>
        After disconnecting, you can delete Asolytics from the Users & Access section in the App Store Console
      </div>
    </div>
    <div class="buttons">
      <base-button height="40px"
                   width="100px"
                   border-radius="4px"
                   @click="closeModal"
                   classes="cancel-button"
                   mode="just-text">
        Cancel
      </base-button>

      <base-button height="40px"
                   width="180px"
                   :disabled="inProcess"
                   @click="disconnect"
                   border-radius="4px"
                   classes="disconnect-button"
                   mode="outline">
        Yes, Disconnect
      </base-button>
    </div>
  </div>
</template>
<script>
import AppTitle from "@/components/Billets/AppTitle/index.vue";
import {httpRequest} from "@/api";

export default {
  name: 'DisconnectEmailModal',
  components: {
    AppTitle,
  },
  props: {
    app: Object,
    connectAppId: Number,
  },
  data() {
    return {
      inProcess: false,
    }
  },
  methods: {
    closeModal() {
      this.$emit('close-modal', false);
    },
    async disconnect() {
      this.inProcess = true;
      let formData = new FormData();
      formData.append('connect_app_id', this.connectAppId);
      await httpRequest(
        'POST',
        process.env.VUE_APP_API_URL + this.$ApiUrls.integrations.APP_STORE_CONNECT_DISCONNECT_EMAIL,
        formData
      );
      this.$emit('close-modal', true);
    },
  },
}
</script>
<style lang="scss">

</style>
